import React, { useEffect } from 'react';
import { useAuth } from './authentication';
import * as ContentDesign from 'src/components/layouts/content/theme';
import { Grid as GridLoader } from 'svg-loaders-react';

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    setTimeout(() => logout({ to: '/' }), 3000);
  }, []);

  return (
    <div className="flex flex-row items-baseline">
      <ContentDesign.P>
        Vous allez bientôt être redirigé vers la page d'accueil.
      </ContentDesign.P>
      <GridLoader fill="rgb(15, 191, 228)" className="w-4 h-4 ml-4" />
    </div>
  );
};

export default Logout;
