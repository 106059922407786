import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import Logout from 'src/components/logout';
import SEO from 'src/components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Merci et à bientôt`}</h2>
    <SEO title="Déconnexion" mdxType="SEO" />
    <p>{`Vous êtes maintenant déconnecté(e).`}</p>
    <Logout mdxType="Logout" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      